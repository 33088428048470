@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

html,
body,
#root {
  @apply text-gray-700 antialiased;
  font-family: Roboto, sans-serif;
  height: 100%;
}

.pill {
  @apply bg-white rounded-full px-3 py-1 border-2;
}

[disabled] {
  cursor: default;
}

.chevron:hover > * {
  fill: white;
}
